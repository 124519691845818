import { createSlice } from "@reduxjs/toolkit"
import { verifyDocument } from "./VerifyAction";

//for creating new Order
const verifySlice = createSlice({
    name: 'Verify',
    initialState: { loading: false, error: false, verify: {}, verifySuccess: false},
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
        clearVerifySuccess: (state) => {
            state.verifySuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyDocument.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyDocument.fulfilled, (state, action) => {
                state.verify = action.payload.data;
                state.verifySuccess = true;
                state.loading = false;
                state.error = false;
            })
            .addCase(verifyDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


const verifyAction = verifySlice.actions;

export {verifyAction, verifySlice};