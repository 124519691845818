import React from 'react'

const StatisticSection = () => {
  return (
    <div className='statistic-section' >
      <div className='statistic-section__heading'>Visas On Time</div>
      <div className='statistic-section__subheading'>We ensure timely visa processing with the highest customer satisfaction</div>
    <div className='statisticSection'>
        <div className='statisticSection__container'>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-customers"></use>
                 </svg>
               </div>
               <div className='statisticSection__text '>Users</div>
               <div className='statisticSection__num'>8k</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-review"></use>
                 </svg>
               </div>
               <div className='statisticSection__text '>Visa Applied</div>
               <div className='statisticSection__num'>3k</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-earth"></use>
                 </svg>
               </div>
               <div className='statisticSection__text '>Countries</div>
               <div className='statisticSection__num'>21</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-partners"></use>
                 </svg>
               </div>
               <div className='statisticSection__text '>Partners</div>
               <div className='statisticSection__num'>139</div>
           </div>
        </div>
    </div>
    </div>
  )
}

export default StatisticSection