import { createSlice } from "@reduxjs/toolkit"
import {getTestimonialCards} from './TestimonialCardAction'

//for creating new Order
const testimonialCardSlice = createSlice({
    name: 'ReviewCard',
    initialState: { loading: true, error: false, testimonialCards: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestimonialCards.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTestimonialCards.fulfilled, (state, action) => {
                state.testimonialCards = action.payload.testimonials;
                state.loading = false;
                state.error = false;
            })
            .addCase(getTestimonialCards.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


const testimonialCardAction = testimonialCardSlice.actions;

export {testimonialCardAction, testimonialCardSlice};