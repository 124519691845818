import React from 'react'

const AppliedVisa = ({visaApplied}) => {
  return (
    <div>
       <div>
         <img alt='visa'></img>
       </div>
       <div>
        <div></div>
        <div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>
            <div></div>
            <div></div>
            <div></div>
        </div>
       </div>
    </div>
  )
}

export default AppliedVisa