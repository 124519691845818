import React, { useState, useEffect } from 'react';

const Payment = ({ deliverDays, numOfAddOns, selectedCurrency, addOns, visaDetail, numOfTravellers }) => {
  const [discount, setDiscount] = useState(0);
  const [date, setDate] = useState('');
  const [totalvisaPrice, setTotalvisaPrice] = useState(0);

  const currencySymbol = selectedCurrency === 'USD' ? '$' : '₹';
  const addOnsvisaPrice = 0;
    // parseFloat(addOns[0]?.price[selectedCurrency].replace(/[^0-9.-]+/g, "")) +
    // parseFloat(addOns[0]?.visaFuCharges[selectedCurrency].replace(/[^0-9.-]+/g, "")) +
    // parseFloat(addOns[0]?.tax[selectedCurrency].replace(/[^0-9.-]+/g, ""));

  const visaPrice = parseFloat(visaDetail?.price[selectedCurrency].replace(/[^0-9.-]+/g, ""));
  const visaFuCharges = parseFloat(visaDetail?.visaFuCharges[selectedCurrency].replace(/[^0-9.-]+/g, ""));
  const tax = parseFloat(visaDetail?.tax[selectedCurrency].replace(/[^0-9.-]+/g, ""));

  const calculateDeliveryDate = (deliverDays) => {
      const currentDate = new Date();
      let remainingDays = parseInt(deliverDays, 10);
      let deliveryDate = new Date(currentDate);
  
      // Loop until all deliverable days are counted
      while (remainingDays > 0) {
        deliveryDate.setDate(deliveryDate.getDate() + 1); // Move to the next day
  
        // Check if the day is not Saturday (6) or Sunday (0)
        if (deliveryDate.getDay() !== 6 && deliveryDate.getDay() !== 0) {
          remainingDays--; // Only count weekdays
        }
      }
  
      // Add one additional day after calculation
      deliveryDate.setDate(deliveryDate.getDate());
  
      return deliveryDate;
    };
    
    useEffect(() => {
      if (deliverDays) {
        const deliverDay = parseInt(deliverDays, 10);
    
        if (!isNaN(deliverDay)) {
          const calculatedDate = calculateDeliveryDate(deliverDay);
          const dateOptions = { day: '2-digit', month: 'short' };
          const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

          const formattedDate = calculatedDate.toLocaleDateString('en-GB', dateOptions);
          const formattedTime = calculatedDate.toLocaleTimeString('en-GB', timeOptions);

          setDate(`${formattedDate}, ${formattedTime}`);
        }
      }
    }, [deliverDays]);

  useEffect(() => {
    function getBestDiscount(numOfTravel, percentageOffArray) {
      const applicableDiscounts = percentageOffArray.filter(item => item.numOfTravel <= numOfTravel);
      if (applicableDiscounts.length > 0) {
        const bestDiscount = applicableDiscounts.sort((a, b) => b.numOfTravel - a.numOfTravel)[0];
        return bestDiscount.percentageOff;
      }
      return 0;
    }

    setDiscount(getBestDiscount(numOfTravellers, visaDetail?.percentageOff));
    let total =
      numOfTravellers * (visaPrice + visaFuCharges + tax) -
      (((visaFuCharges + tax) * discount * numOfTravellers) / 100) +
      numOfAddOns * addOnsvisaPrice;

      // console.log(tax, discount, visaFuCharges, visaPrice, numOfTravellers, addOnsvisaPrice)
    setTotalvisaPrice(total);
  }, [numOfTravellers, visaPrice, tax, visaFuCharges, discount, visaDetail?.percentageOff, numOfAddOns, addOnsvisaPrice]);

   const handleSubmit = () =>{
    
   }

  return (
    <div className="payment">
      <div className="payment__header">
        <svg className="payment__icon" aria-hidden="true">
          <use href="\sprite.svg#icon-sheet"></use>
        </svg>
        <div className="payment__date">Visa On {date}</div>
        <svg className="payment__icon payment__icon--close" aria-hidden="true">
          <use href="\sprite.svg#icon-exCircle"></use>
        </svg>
      </div>

      <div className="payment__details">
        <div className="payment__heading">{visaDetail?.heading}</div>
        <div className="payment__row">
          <div className="payment__label">Visa Fees</div>
          <div className="payment__value">{currencySymbol}{currencySymbol==='₹' ? parseInt(visaPrice * numOfTravellers):visaPrice * numOfTravellers}</div>
        </div>
        <div className="payment__row">
          <div className="payment__charges">
            <div className="payment__charges-details">
              <div className="payment__label">VisaFu Charges</div>
              {discount > 0 && <div className="payment__charges-discount">{discount}% off</div>}
            </div>
            <div className="payment__charges-note">(including tax)</div>
          </div>
          <div className="payment__value">
            {currencySymbol}{currencySymbol==='₹' ?parseInt((visaFuCharges + tax) * numOfTravellers - ((visaFuCharges + tax) * discount * numOfTravellers) / 100):(visaFuCharges + tax) * numOfTravellers - ((visaFuCharges + tax) * discount * numOfTravellers) / 100}
          </div>
        </div>
        {numOfAddOns > 0 && (
          <div className="payment__row">
            <div className="payment__label">AddOns Price</div>
            <div className="payment__value">{currencySymbol}{currencySymbol==='₹' ? parseInt((numOfAddOns * addOnsvisaPrice)):(numOfAddOns * addOnsvisaPrice)}</div>
          </div>
        )}
        <div className="payment__row payment__row--total">
          <div className="payment__label payment__label--total">Total</div>
          <div className="payment__value payment__value--total">{currencySymbol}{currencySymbol==='₹' ? parseInt(totalvisaPrice): totalvisaPrice}</div>
        </div>
        <div className='payment__button--box'>
          <button className="payment__button" onClick={handleSubmit}>Pay {currencySymbol}{currencySymbol==='₹' ? parseInt(totalvisaPrice):totalvisaPrice}</button>
        </div>
      </div>
    </div>
  );
};

export default Payment;