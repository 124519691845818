import {configureStore} from '@reduxjs/toolkit';
import  {visaCardSlice} from './VisaCard/VisaCardReducer';
import { testimonialCardSlice } from './Testimonial/TestimonialCardReducer';
import { visaDetailSlice } from './Visa/VisaReducer';
import { verifySlice } from './Verify/VerifyReducer';
import { addOnsSlice } from './AddOn/AddOnReducer';
import { userSlice } from './User/UserReducer';

const Store = configureStore({
    reducer:{
        visaCards: visaCardSlice.reducer,
        testimonialCards: testimonialCardSlice.reducer,
        visaDetail: visaDetailSlice.reducer,
        verify: verifySlice.reducer,
        addOns: addOnsSlice.reducer,
        user: userSlice.reducer
    }
});
export default Store;