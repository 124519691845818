import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getVisaDetail } from '../../store/Visa/VisaAction';
import VisaFrequentlyAsked from './VisaFrequentlyAsked';
import VisaReview from './VisaReview';
import VisaPriceCalculator from './VisaPriceCalculator';
import VisaStats from './VisaStats';
import Loading from '../../components/Loading';
import NotFound from '../../layout/NotFound';
import VisaOffer from './VisaOffer';
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo';


const formatDate = (date) => {
  const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

const getDate = (deliverDays) => {
  const currentDate = new Date();
  let remainingDays = parseInt(deliverDays, 10);
  let deliveryDate = new Date(currentDate);

  // Loop until all deliverable days are counted
  while (remainingDays > 0) {
    deliveryDate.setDate(deliveryDate.getDate() + 1); // Move to the next day

    // Check if the day is not Saturday (6) or Sunday (0)
    if (deliveryDate.getDay() !== 6 && deliveryDate.getDay() !== 0) {
      remainingDays--; // Only count weekdays
    }
  }

  const formattedDate = formatDate(deliveryDate); // Format as needed
  return formattedDate;
};



const VisaDetail = ({ selectedCurrency }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, visaDetail, error } = useSelector((state) => state.visaDetail);

  useEffect(() => {
    dispatch(getVisaDetail({ id }));
  }, [dispatch, id])

  const seo = getSEOData("visa", visaDetail?.countryName);


  return (
   <>{loading?<Loading/>:error.status===404?<NotFound/>:<div className='visaDetail'>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
      </Helmet>
      <div className='visaDetail__container'>
        <div className='visaDetail__box--left'>
          <div className='visaDetail__heading'>{visaDetail.heading}</div>
          <div className='visaDetail__image-box'>
            <img src={visaDetail.visaImage} alt={visaDetail.country} className='visaDetail__img'></img>
          </div>
          <div className='visaDetail__apply-text'>Apply now for guaranteed visa</div>
          <div className='visaDetail__get-date'>
            <svg className='visaDetail__icon' aria-hidden="true">
              <use href="/sprite.svg#icon-clock"></use>
            </svg>
            Get On &nbsp; <span>{getDate(visaDetail?.deliverDays)}</span>
          </div>
          <div className='visaDetail__document'>
            <div className='visaDetail__document--box1'>
              <svg className='visaDetail__document-icon' aria-hidden="true">
                <use href="/sprite.svg#icon-document"></use>
              </svg>
              Document Required
            </div>
            <div className='visaDetail__document--box2'>
              {visaDetail?.documentRequirement?.photo &&
                <div className='visaDetail__box'>
                  <svg className='visaDetail__document-icon' aria-hidden="true">
                    <use href="/sprite.svg#icon-user"></use>
                  </svg>
                  Photo
                </div>
              }
              {visaDetail?.documentRequirement?.passport &&
                <div className='visaDetail__box'>
                  <svg className='visaDetail__document-icon' aria-hidden="true">
                    <use href="/sprite.svg#icon-passport"></use>
                  </svg>
                  Passport
                </div>
              }
              {visaDetail?.documentRequirement?.usVisa &&
                <div className='visaDetail__box'>
                  <svg className='visaDetail__document-icon' aria-hidden="true">
                    <use href="/sprite.svg#icon-visa"></use>
                  </svg>
                  Us Visa
                </div>
              }
            </div>
          </div>
          <div className='visaDetail__freqAsked'>
            <div className='visaDetail__subHeading'>Frequently Asked Questions</div>
            <VisaFrequentlyAsked faqData={visaDetail?.faq}></VisaFrequentlyAsked>
          </div>
        </div>
        <div className='visaDetail__box--right'>
          <VisaStats visaDetail={visaDetail}></VisaStats>
          {
            (Object.keys(visaDetail).length > 0) && <VisaPriceCalculator visaDetail={visaDetail} selectedCurrency={selectedCurrency}></VisaPriceCalculator>
          }
          <VisaOffer></VisaOffer>
        </div>
        
      </div>
      <div className='visaDetail__review'>
            <VisaReview visaDetail={visaDetail}></VisaReview>
          </div>
    </div >}</>
  )
}

export default VisaDetail