import React from 'react'

const VisaStats = ({visaDetail}) => {
  return (
    <div className='visaStats'>
        <div className='visaStats__box'>
            <svg className="visaStats__icon" aria-hidden="true">
                <use href="/sprite.svg#icon-type"></use>
            </svg>
            <div className='visaStats__text'>Visa Type: {visaDetail?.visaType}</div>
        </div>
        <div className='visaStats__box'>
            <svg className="visaStats__icon" aria-hidden="true">
                <use href="/sprite.svg#icon-entry"></use>
            </svg>
            <div className='visaStats__text'>Entry: {visaDetail?.entry}</div>
        </div>
        <div className='visaStats__box'>
            <svg className="visaStats__icon" aria-hidden="true">
                <use href="/sprite.svg#icon-calendar"></use>
            </svg>
            <div className='visaStats__text'>Length of stay: {visaDetail?.lengthOfStay}</div>
        </div>
        <div className='visaStats__box'>
            <svg className="visaStats__icon" aria-hidden="true">
                <use href="/sprite.svg#icon-alarm"></use>
            </svg>
            <div className='visaStats__text'>Validity Period: {visaDetail?.validationPeriod}</div>
        </div>
    </div>
  )
}

export default VisaStats