import React from 'react';
import HomeMainSection from './HomeMainSection';
import VisaCardSection from './VisaCardSection';
import StatisticSection from './StatisticSection';
import Review from './Review';
import { Helmet } from 'react-helmet';
import getSEOData from '../../components/Seo';

const Home = ({currency}) => {
  const seo = getSEOData('home');

  return (
    <div className='home'>
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
    </Helmet>
     <HomeMainSection></HomeMainSection>
     <VisaCardSection currency={currency}></VisaCardSection>
     <StatisticSection></StatisticSection>
     <Review></Review>
    </div>
  )
}

export default Home