import React, { useRef, useState, useEffect } from 'react';

const Slider = ({ cards, cardWidth, cardMargin, component: Component, setWhite = false }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleCards, setVisibleCards] = useState(3); 
    const wrapperRef = useRef(null);

    const totalCards = cards.length;

    

    useEffect(() => {

        const calculateVisibleCards = () => {
            if (wrapperRef.current) {
                const containerWidth = wrapperRef.current.offsetWidth;
                const totalCardWidth = cardWidth + cardMargin; 
                const visible = Math.floor(containerWidth / (totalCardWidth * 10));
                setVisibleCards(visible > 0 ? visible : 1);
            }
        };

        calculateVisibleCards(); 
        window.addEventListener('resize', calculateVisibleCards);

        return () => {
            window.removeEventListener('resize', calculateVisibleCards); 
        };
    }, [cardMargin, cardWidth]);

    const handleNext = () => {
        const maxIndex = totalCards - visibleCards;
        setCurrentIndex(currentIndex < maxIndex ? currentIndex + 1 : maxIndex);

    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    // Dynamically adjust transform to show last visible cards at the end
    const transformValue = -currentIndex * (cardWidth + cardMargin);

    return (
        <div className="slider-container">
            <div className="slider-wrapper" ref={wrapperRef}>
                <div
                    className="slider-cards"
                    style={{ transform: `translateX(${transformValue}rem)` }}
                >
                    {cards.map((card, index) => (
                        <Component card={card} id={index} active={currentIndex === index} key={index} />
                    ))}
                </div>
            </div>
            {setWhite ? (
                <div className='slider__buttons'>
                    {currentIndex === 0 ? (
                        <button className="prev-button" disabled={true}>
                            <svg className='prev-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-left-click-white-disable"></use>
                            </svg>
                        </button>
                    ) : (
                        <button className="prev-button" onClick={handlePrev}>
                            <svg className='prev-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-left-click-white-visible"></use>
                            </svg>
                        </button>
                    )}
                    {currentIndex >= totalCards - visibleCards ? (
                        <button className="next-button" disabled={true}>
                            <svg className='next-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-right-click-white-disable"></use>
                            </svg>
                        </button>
                    ) : (
                        <button className="next-button" onClick={handleNext}>
                            <svg className='next-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-right-click-white-visible"></use>
                            </svg>
                        </button>
                    )}
                </div>
            ) : (
                <div className='slider__buttons'>
                    {currentIndex === 0 ? (
                        <button className="prev-button" disabled={true}>
                            <svg className='prev-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-left-click-disable"></use>
                            </svg>
                        </button>
                    ) : (
                        <button className="prev-button" onClick={handlePrev}>
                            <svg className='prev-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-left-click-visible"></use>
                            </svg>
                        </button>
                    )}
                    {currentIndex >= totalCards - visibleCards ? (
                        <button className="next-button" disabled={true}>
                            <svg className='next-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-right-click-disable"></use>
                            </svg>
                        </button>
                    ) : (
                        <button className="next-button" onClick={handleNext}>
                            <svg className='next-icon' aria-hidden="true">
                                <use href="sprite.svg#icon-right-click-visible"></use>
                            </svg>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Slider;
