import React, { useState, useEffect } from 'react';
import DocumentUpload from '../../layout/DocumentUpload';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import NotFound from '../../layout/NotFound';
import LinkExpired from '../../layout/LinkExpired';
import { getVisaDetail } from '../../store/Visa/VisaAction';
import Navigation from '../../components/Navigation';
import Information from '../../components/Information';
import AddOns from '../../layout/AddOns';
import Payment from '../../layout/Payment';

const TravellerNavigation = ({selectedCurrency}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const departureDate = searchParams.get('departureDate');
  const numTravellers = searchParams.get('numTravellers');
  const [minSelectableDate, setMinSelectableDate] = useState('');
  const numOfTravellers = parseInt(numTravellers) || 1;
  const [currentTraveller, setCurrentTraveller] = useState(1);
  const [page, setPage] = useState("document");
  const [numOfAddOns, setNumOfAddOns] = useState(0);
  const [addOns, setAddOns] = useState({});

  const dispatch = useDispatch();
  const { loading, visaDetail, error } = useSelector((state) => state.visaDetail);

  useEffect(() => {
    dispatch(getVisaDetail({ id }));
  }, [dispatch, id]);


  useEffect(() => {
    const currentDate = new Date();
    const deliverDays = parseInt(visaDetail?.deliverDays, 10);

    if (!isNaN(deliverDays)) {
      currentDate.setDate(currentDate.getDate() + deliverDays + 1);
      // Format the date to 'YYYY-MM-DD' for the input field
      const formattedDate = currentDate.toISOString().split('T')[0];
      setMinSelectableDate(formattedDate);
    }
  }, [visaDetail?.deliverDays]);

  return (<>
    {loading ? <Loading /> : (error.status === 404 || !departureDate || !minSelectableDate) ? <NotFound /> :
      new Date(minSelectableDate) > new Date(departureDate) ? <LinkExpired /> :
        <div className='travellerNavigation'>
          <Navigation page={page}></Navigation>
          {page === 'document' && <DocumentUpload numOfTravellers={numOfTravellers} visaDetail={visaDetail} currentTraveller={currentTraveller} setCurrentTraveller={setCurrentTraveller} setPage={setPage}/>}
          {/* {page === 'addOns' && <AddOns deliverDays={parseInt(visaDetail?.deliverDays, 10)} numOfAddOns={numOfAddOns} setNumOfAddOns={setNumOfAddOns} setPage={setPage} selectedCurrency={selectedCurrency} setAddOns={setAddOns}></AddOns>} */}
          {page === 'payment' && <Payment deliverDays={parseInt(visaDetail?.deliverDays, 10)} numOfAddOns={numOfAddOns} selectedCurrency={selectedCurrency} addOns={addOns} visaDetail={visaDetail} numOfTravellers={numOfTravellers}></Payment>}
          <Information></Information>
        </div>}
  </>
  );
};

export default TravellerNavigation;