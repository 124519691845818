import React from 'react'

const Information = () => {
  return (
    <div className="information">
      <div className="information__box">
        <div className="information__header">
          <svg className="information__icon" aria-hidden="true">
            <use href="\sprite.svg#icon-closeTime"></use>
          </svg>
          <div className="information__title">Time for Close-Up</div>
        </div>
        <div className="information__text">
          Your Photo is important. Make sure your photo showcases your lovely smile, has a clear bright background and your face is clearly visible.
        </div>
        <div className="information__steps">
          <div className="information__step">
            <div className="information__step-number">1</div>
            <div className="information__step-description">Position your head in oval</div>
          </div>
          <div className="information__step">
            <div className="information__step-number">2</div>
            <div className="information__step-description">Make sure you’re in a well-lit area</div>
          </div>
          <div className="information__step">
            <div className="information__step-number">3</div>
            <div className="information__step-description">Remove glasses</div>
          </div>
          <div className="information__step">
            <div className="information__step-number">4</div>
            <div className="information__step-description">Avoid glare and blurs</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Information