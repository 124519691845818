import { createSlice } from "@reduxjs/toolkit"
import { logIn, verifyOTP, loadUser } from "./UserAction";

//for creating new Order
const userSlice = createSlice({
    name: 'User',
    initialState: { loading: false, error: false, success:false, message: '', verifyOTPLoading: false, verifyOTPError: false, user:{},verifyOTPMessage:'', verifyOTPSuccess: false, isAuthenticated: false, loadUserLoading: false, loadUserError: false, loadUserSuccess: false, loadUserMessage: ''},
    reducers: {
        clearError: (state) => {
            state.error = false;
        }, 
        clearSuccess: (state) =>{
            state.success = false;
        },
        clearVerifyOTPError: (state) => {
            state.verifyOTPError = false;
        }, 
        clearVerifyOTPSuccess: (state) =>{
            state.verifyOTPSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(logIn.fulfilled, (state, action) => {
                state.message = action?.payload?.message;
                state.loading = false;
                state.error = false;
                state.success = true;
            })
            .addCase(logIn.rejected, (state, action) => {
                state.loading = false;
                state.error = false;
                state.message = action?.payload;
                state.success = false;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.verifyOTPLoading = true;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.user = action?.payload?.user;
                state.verifyOTPMessage= action?.payload?.message;
                state.isAuthenticated=true;
                state.verifyOTPLoading = false;
                state.verifyOTPError = false;
                state.verifyOTPSuccess = true;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.verifyOTPMessage= action?.payload;
                state.verifyOTPLoading = false;
                state.verifyOTPError = true;
                state.verifyOTPSuccess = false;
            })
            .addCase(loadUser.pending, (state) => {
                state.loadUserLoading = true;
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.user = action?.payload?.user;
                state.isAuthenticated=true;
                state.loadUserLoading = false;
                state.loadUserError = false;
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.loadUserMessage= action?.payload;
                state.loadUserLoading = false;
                state.loadUserError = true;
                state.loadUserSuccess = false;
            });
    }
});


const userAction = userSlice.actions;

export {userAction, userSlice};