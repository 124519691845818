import React, { useEffect } from 'react';
import Slider from '../../../components/Slider';
import ReviewCard from '../../../components/ReviewCard';
import { useSelector, useDispatch } from 'react-redux'
import { getTestimonialCards } from '../../../store/Testimonial/TestimonialCardAction';
import Loading from '../../../components/Loading';

const Review = () => {
  const dispatch = useDispatch();

  const { testimonialCards, loading } = useSelector((state) => state.testimonialCards);

  useEffect(() => {
    dispatch(getTestimonialCards({}));
  }, [dispatch])

  return (
    <div className='review'>
      <div className='review__heading'>What Our Customers Say</div>
      <div className='review__subheading'>Trusted by thousands of customers worldwide.</div>
      {loading?<Loading/>:<Slider cards={testimonialCards} cardWidth={33} cardMargin={2} component={ReviewCard}></Slider>}
    </div>
  )
}


export default Review