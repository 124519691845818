import { createSlice } from "@reduxjs/toolkit"
import { getAddOns } from "./AddOnAction";

const addOnsSlice = createSlice({
    name: 'AddOns',
    initialState: { loading: true, error: false, addOns: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddOns.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAddOns.fulfilled, (state, action) => {
                state.addOns = action.payload.data;
                state.loading = false;
                state.error = false;
            })
            .addCase(getAddOns.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

const addOnsAction = addOnsSlice.actions;

export {addOnsAction, addOnsSlice};