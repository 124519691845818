import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from './store/Store';
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
            <Provider store={Store}>
               <App />
               <ToastContainer/>
            </Provider>
      </BrowserRouter>
  </React.StrictMode>
);