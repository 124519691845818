import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms">
      <h1 className="terms__title">Terms and Conditions</h1>

      <p className="terms__intro">
        These Terms and Conditions govern the use of the services provided by
        VisaFu (“Company,” “We,” or “Us”) and constitute a legally binding
        agreement between You (“User” or “Customer”) and the Company. By using
        or accessing our website and services, You agree to comply with these
        terms.
      </p>

      <section className="terms__section">
        <h2 className="terms__heading">1. Acceptance of Terms</h2>
        <p className="terms__text">
          1.1. Your access to and use of the VisaFu services is conditioned on
          Your acceptance of and compliance with these Terms. These Terms apply
          to all visitors, users, and others who access or use the Service.
        </p>
        <p className="terms__text">
          1.2. If You do not agree with any part of these Terms, You must not
          use the Service.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">2. Eligibility</h2>
        <p className="terms__text">
          2.1. You represent that You are at least 18 years of age. VisaFu does
          not permit those under 18 to use its services.
        </p>
        <p className="terms__text">
          2.2. Your use of the Service is also conditioned on Your acceptance of
          our Privacy Policy, which details how we collect, use, and protect
          your personal information.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">3. Visa Applications</h2>
        <p className="terms__text">
          3.1. VisaFu acts as an intermediary between You and the Embassy but
          has no control over the decision-making process for visa applications.
          The approval or rejection of a visa is at the sole discretion of the
          Embassy. VisaFu is not responsible for any delays, rejections, or
          requests for additional documentation.
        </p>
        <p className="terms__text">
          3.2. You acknowledge that providing incomplete or inaccurate
          information may lead to the delay or denial of your visa application.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">4. User Information</h2>
        <p className="terms__text">
          4.1. To use our services, You may need to register an account and
          provide personal details including, but not limited to, Your name,
          email address, phone number, and payment details.
        </p>
        <p className="terms__text">
          4.2. You warrant that the information You provide is accurate,
          complete, and up to date. You are solely responsible for the accuracy
          of the information submitted and must respond to VisaFu communications
          within 24 hours.
        </p>
      </section>
      
      <section className="terms__section">
        <h2 className="terms__heading">5. Payments and Fees</h2>
        <p className="terms__text">
          5.1. You agree to pay all fees for services rendered by VisaFu. VisaFu
          reserves the right to revise fees prior to accepting an order or after
          accepting an order in the event of external circumstances (e.g.,
          government actions, customs duties).
        </p>
        <p className="terms__text">
          5.2. Payment must be made using a valid credit or debit card or other
          accepted payment methods.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">6. Account Responsibility</h2>
        <p className="terms__text">
          6.1. You are responsible for maintaining the confidentiality of Your
          account information, including Your password, and for all activities
          conducted through Your account.
        </p>
        <p className="terms__text">
          6.2. VisaFu is not liable for any unauthorized access to Your account
          due to a failure to safeguard Your login credentials.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">7. Limitation of Liability</h2>
        <p className="terms__text">
          7.1. VisaFu shall not be liable for any direct or indirect damages
          resulting from the use or inability to use the Service, including, but
          not limited to, damages for delays in processing or rejection of visa
          applications.
        </p>
        <p className="terms__text">
          7.2. VisaFu’s total liability shall not exceed the total amount paid
          by the User for the service.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">8. Intellectual Property</h2>
        <p className="terms__text">
          8.1. All content, logos, trademarks, and proprietary information
          displayed on the VisaFu website are owned by VisaFu or its licensors.
          You are granted a limited, non-exclusive, revocable license to access
          the website for personal use only.
        </p>
        <p className="terms__text">
          8.2. You may not modify, copy, distribute, or exploit any part of the
          content without express permission from VisaFu.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">9. Governing Law</h2>
        <p className="terms__text">
          These Terms shall be governed and construed in accordance with the
          laws of India, without regard to its conflict of law provisions.
        </p>
      </section>

      <section className="terms__section">
        <h2 className="terms__heading">10. Amendments</h2>
        <p className="terms__text">
          VisaFu reserves the right to modify these Terms at any time. Any
          changes will be posted on this page, and You are encouraged to review
          the Terms periodically.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
