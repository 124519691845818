import React from 'react';
import { useParams } from 'react-router-dom';

const LinkExpired = () => {
    const { id } = useParams();
  return (
    <div className="link-expired">
      <h1 className="link-expired__heading">Link Expired</h1>
      <p className="link-expired__text">
        The link you are trying to access has expired or is no longer valid.
      </p>
      <a href={"/visa/"+id} className="link-expired__link">Go back to page</a>
    </div>
  );
};

export default LinkExpired;