import React from 'react'

const Navigation = ({ page }) => {
  return (
    <div className='navigation'>
      <div className='navigation__container'>
        <div className='navigation__box'>
          <div className='navigation__topic navigation__topic--filled'>Dates</div>
          <svg className='navigation__icon navigation__icon--filled' aria-hidden="true">
            <use href="\sprite.svg#icon-airplane-date"></use>
          </svg>
           <svg
            className='navigation__icon2 navigation__icon2--filled'
            aria-hidden="true"
          >
            <use href="/sprite.svg#icon-line"></use>
          </svg>
        </div>
        <div className='navigation__box'>
          <div className={(page==='document'||page==='addOns'||page==='payment')?'navigation__topic navigation__topic--filled':'navigation__topic'}>Uploads</div>
          <svg className={(page==='addOns'||page==='payment')?'navigation__icon navigation__icon--filled':'navigation__icon'} aria-hidden="true">
            <use href="\sprite.svg#icon-uploads"></use>
          </svg>
          <svg className={(page==='addOns'||page==='payment')?'navigation__icon2 navigation__icon2--filled':'navigation__icon2'} aria-hidden="true">
            <use href="\sprite.svg#icon-line"></use>
          </svg>
        </div>
        {/* <div className='navigation__box'>
          <div className={(page==='addOns' || page==='payment')?'navigation__topic navigation__topic--filled':'navigation__topic'}>AddOns</div>
          <svg className={(page==='payment')?'navigation__icon navigation__icon--filled':'navigation__icon'} aria-hidden="true">
            <use href="\sprite.svg#icon-addOns"></use>
          </svg>
          <svg className={(page==='payment')?'navigation__icon2 navigation__icon2--filled':'navigation__icon2'} aria-hidden="true">
            <use href="\sprite.svg#icon-line"></use>
          </svg>
        </div> */}
        <div className='navigation__box'>
          <div className={page==='payment'?'navigation__topic navigation__topic--filled':'navigation__topic'}>Payment</div>
          <svg className='navigation__icon' aria-hidden="true">
            <use href="\sprite.svg#icon-checkOut"></use>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Navigation