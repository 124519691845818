import React, { useState } from 'react';

const VisaFrequentlyAsked = ({ faqData }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='faq'>
      {faqData?.map((item, index) => (
        <div key={index} className='faq__item'>
          <div className='faq__question' onClick={() => toggleFAQ(index)}>
            {item.question}
            <svg className={`faq__icon ${openIndex === index ? 'faq__icon--open' : ''}`} aria-hidden="true">
              <use href="/sprite.svg#icon-chevon"></use>
            </svg>
          </div>
          {openIndex === index && (
            <div
              className='faq__answer'
              dangerouslySetInnerHTML={{ __html: item.answer }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default VisaFrequentlyAsked;