import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const Navbar = ({ selectedCurrency, onCurrencyChange }) => {

  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <nav className='navbar'>
      <div className='navbar__box'>
        <a href="/" className="navbar__logo-link">
          <img src="/images/simple-logo.svg" alt="MyCompany Logo - Visa Service" className="navbar__logo" />
        </a>
        <ul className="navbar__menu" aria-label="Main navigation">
          {/* Currency Select Option */}
          <li className="navbar__menu-item">
            <select
              className='navbar__currency-select'
              value={selectedCurrency}
              onChange={onCurrencyChange}
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
          </li>

          <li className="navbar__menu-item">
            <Link to="/aboutUs" className='navbar__menu-link'>
              About Us
            </Link>
          </li>
          <li className="navbar__menu-item">
            <Link to="/contactUs" className='navbar__menu-link'>
              Contact Us
            </Link>
          </li>
          {!isAuthenticated ? <Link to="/signIn" className='navbar__menu-link'>
            <button className="navbar__menu-btn">
              Sign In

            </button>
          </Link>
            : <Link to="/dashboard" className='navbar__menu-link'>
              <svg className="navbar__dashboard-icon" aria-hidden="true" role="img">
                <use href="/sprite.svg#icon-Margin-2"></use>
              </svg>
            </Link>}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;