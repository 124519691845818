import React from 'react'
import StarRating from '../../../components/StarRating'
import VisaReviewCard from '../../../components/VisaReviewCard'

const VisaReview = ({visaDetail}) => {
  return (
    <div className='visaReview'>
      <div className='visaReview__heading'>{visaDetail?.overallRating?.toFixed(2)}/5</div>
      <div className='visaReview__detail'>
        <div className='visaReview__star'>
          <StarRating rating={visaDetail?.overallRating}></StarRating>
        </div>
        <div className='visaReview__para'>from {visaDetail?.reviews?.length} reviews</div>
      </div>
      <div className='visaReview__card-box'>
        {visaDetail?.reviews?.map((review,index)=>{
          return <VisaReviewCard review={review} index={index} key={index}></VisaReviewCard>
        })}
      </div>
    </div>
  )
}

export default VisaReview